/* chatContainer.css */
.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #1c1c1c;
  height: 100vh;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.messages {
  padding: 20px;
  flex: 1;
  overflow-y: auto;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
  word-wrap: break-word;
}

.user-message {
  background-color: #4a90e2;
  color: #e0e0e0;
  align-self: flex-end;
  text-align: left;
}

.bot-message {
  background-color: #333;
  color: #e0e0e0;
  align-self: flex-start;
  text-align: left;
}

.message-form {
  display: flex;
  padding: 20px;
  background: #2a2a2a;
  border-top: 1px solid #333;
}

.message-form input {
  flex: 1;
  padding: 10px;
  border: 1px solid #555;
  border-radius: 20px;
  background: #1c1c1c;
  color: #e0e0e0;
  outline: none;
}

.message-form button {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 20px;
  background-color: #4a90e2;
  color: white;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.message-form button:hover {
  background-color: #357ab8;
}
