#paypal-button-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  background: #f8f9fa; /* Light background color */
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal-content {
    background-color: #ffffff; /* White background */
    color: #333333; /* Dark text color */
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.plan-details {
    margin-bottom: 20px;
}

.plan-details h2 {
    font-size: 28px;
    margin-bottom: 10px;
    color: #007bff; /* Blue heading */
}

.plan-details p {
    font-size: 18px;
    margin: 5px 0;
    color: #555555; /* Gray text */
}

.price {
    font-size: 24px;
    font-weight: bold;
    color: #28a745; /* Green price */
}

#paypal-button-container {
    margin-top: 20px;
}

.close-button {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    background-color: #007bff; /* Blue button */
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.close-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}
