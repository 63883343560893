.cancel-subscription-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel-subscription-modal {
  background: #1c1c1c;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  color: #e0e0e0;
}

.cancel-subscription-modal h2 {
  margin-bottom: 20px;
  color: #e0e0e0;
}

.cancel-subscription-modal p {
  margin-bottom: 10px;
  color: #ccc;
}

.cancel-subscription-modal input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #1c1c1c;
  color: #e0e0e0;
  outline: none;
}

.cancel-subscription-modal .button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cancel-subscription-modal .button-container button {
  width: 100%;
  padding: 10px 20px;
  margin: 5px 0;
  border: none;
  border-radius: 5px;
  background-color: #4a90e2;
  color: white;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.cancel-subscription-modal .button-container button:hover {
  background-color: #357ab8;
}
