/* chatMenu.css */

.chat-menu {
  width: 15%;
  background-color: #2a2a2a;
  border-right: 1px solid #333;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.explanation {
  margin-bottom: 20px;
}

.explanation h2 {
  color: #e0e0e0;
  margin-bottom: 10px;
  font-size: 2rem;
}

.explanation p {
  color: #ccc;
  font-size: 1.2rem;
}

.menu {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  flex-grow: 1;
}

.menu button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #4a90e2;
  color: white;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  font-size: 0.875rem;
}

.menu button:hover {
  background-color: #357ab8;
}

.bullet-button {
  position: relative;
  padding-left: 35px;
}

.bullet-button::before {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: white;
}

.cancel-button {
  margin-top: auto;
  /* Ensure cancel-button has same styles as bullet-button */
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #4a90e2;
  color: white;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  display: block;
  width: 100%;
  font-size: 0.875rem;
}

.cancel-button:hover {
  background-color: #357ab8;
}

.Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: #1c1c1c;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 600px;
    overflow-y: auto;
    max-height: 80%;
    color: #e0e0e0;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
