/* codeExamples.css */

.code-examples {
    width: 100%;
}

.tabs {
    display: flex;
    margin-bottom: 0;
}

.tabs button {
    background-color: #4a4a4a;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 5px;
    font-size: 1rem;
    border-radius: 5px;
}

.tabs button.active, .tabs button:hover {
    background-color: #4a90e2;
}

.code-container {
    background-color: #2a2a2a;
    border-radius: 5px;
    margin-top: 20px;
    padding: 15px;
    width: 100%;
    max-width: 500px;
    height: 300px;
    overflow-y: auto;
    margin-bottom: 20px;
}

.code-container code {
    color: #e0e0e0;
    font-family: 'Courier New', Courier, monospace;
}

.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
}

.input-field {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #2a2a2a;
    color: #e0e0e0;
}

.send-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #4a90e2;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
    margin-top: 20px;
}

.send-button:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
}

.send-button:hover:not(:disabled) {
    background-color: #357ab8;
}

.response {
    margin-top: 20px;
    background-color: #2a2a2a;
    padding: 15px;
    border-radius: 5px;
    width: 100%;
    max-width: 500px;
    overflow-x: auto;
    white-space: pre-wrap;
}

.response h3 {
    margin-top: 0;
    color: #4a90e2;
}
