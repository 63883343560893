/* contactForm.css */
.contact-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-form {
  background: #2a2a2a;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.contact-form h2 {
  margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #1c1c1c;
  color: #e0e0e0;
  outline: none;
}

.contact-form button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  background-color: #4a90e2;
  color: white;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #357ab8;
}

.loading-bar {
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, rgba(74, 144, 226, 1) 0%, rgba(53, 122, 184, 1) 100%);
  animation: loadingAnimation 1s infinite;
  margin-top: 10px;
}

@keyframes loadingAnimation {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}
