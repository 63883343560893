/* successfulPaymentAlert.css */
.successful-payment-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.successful-payment-alert {
  background: #2a2a2a;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.successful-payment-alert-content p {
  margin-bottom: 10px;
  color: #e0e0e0;
}

.successful-payment-alert-content strong {
  color: #fff;
}

.alert-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.alert-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4a90e2;
  color: white;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  font-size: 0.875rem;
  flex: 1;
  margin: 0 5px;
}

.alert-buttons button:hover {
  background-color: #357ab8;
}

.copy-feedback {
  color: #4caf50;
  margin-top: 10px;
  text-align: center;
}
